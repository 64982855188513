//
//
//
//
//
//
//

import { space } from '~/modules/space'
import { isMobile } from 'mobile-device-detect'

const is_iOS = ( !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform) )

if ( ! isMobile ) {
    //@ts-ignore
    window.disable_fullscreen_enter = true;
}

document.documentElement.addEventListener( 'click', () => space.s( 'global_click' ).next(), true )
orientation()
fullscreen()

export default {
    name: 'ActivatorFullScreen',
    data : () => ({
        should_ask_fullscreen : false,
    }),
    mounted() {
        space.s( 'is_fullscreen' )
            .any(
                space.s( 'need_fullscreen' ),
                space.s( 'supposed_fullscreen' ),
                ( f, n, s ) => this.should_ask_fullscreen = n && ! f && ! s && process.env.MAILRU_GAME && isMobile && ! is_iOS
            )
    },
    methods: {
        doActivate() {},
    },
}

function fullscreen() {
    if ( ! canFullscreen() ) {
        return
    }
    space.s( 'need_fullscreen' ).next( isMobile )
    //moved into app.html:
    //space.s( 'global_click' ).to( space.s( 'try_toggle_fullscreen' ) )
    space.s( 'supposed_fullscreen' ).next( is_fullscreen() )

    //sometimes fullscreen'ing doesn't work for some reason, so pretend that we entered it to hide splash screen:
    space.s( 'global_click' ).to( space.s( 'supposed_fullscreen' ) )

    //when user clicks "fullscreen" button it now means changing screen ratio:
    space.s( 'try_toggle_fullscreen' )
        .with( space.s( 'need_fullscreen' ), ( _, need_fullscreen ) => need_fullscreen )
        .filter( need_fullscreen => need_fullscreen )
        .on( () => {
            console.log(" Try enter fullscreen. Is currently fullscreen:", is_fullscreen() )
            //let's try going fullscreen just everytime user clicks - it shouldn't harm anyway (can't properly detect if it's fullscreen when inside iFrame, anyway):
            //if ( ! is_fullscreen() ) {
                if ( process.env.FACEBOOK_INSTANT_GAME ) {
                    document.getElementById('canvas').requestFullscreen()
                }
                else {
                    go_fullscreen()
                }
            //}
        })
    
    space.s('screen_change')
        .map( () => is_fullscreen() )
        .to( space.s( 'is_fullscreen' ) )
}

function orientation() {
    window.addEventListener('resize', () => space.s('screen_change').next())
    window.addEventListener('orientationchange', () => space.s('screen_change').next() )
    setInterval( () => space.s('screen_change').next(), 100 )

    let w, h
    space.s('screen_change')
        .map( () => ({
            w : window.innerWidth,
            h : window.innerHeight,
        }) )
        .filter( () => w != window.innerWidth || h != window.innerHeight )
        .do( new_screen => {
            w = new_screen.w
            h = new_screen.h
            
            //let's just try locking orientation whenever (seems there is a problem in detecting fullscreen when inside iFrame, anyway) - it won't harm:
            /*//you can attempt locking orientation ONLY when it's in fullscreen:
            if ( is_fullscreen() ) {*/
                try {
                    console.log( 'Trying to lock landscape orientation ...' )
                    window.screen.orientation
                        .lock( 'landscape' )
                        .then(
                            success => console.log( 'Landscape orientation successfully locked:', success ),
                            failure => /*console.error( 'Landscape orientation locking failed:', failure )*/{},
                        )
                } catch (e) {
                    console.log( 'Landscape orientation lock error:', e )
                }
            //}
        } )
}

export function is_fullscreen() {
    const doc = window.document;

    return !!(doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement)
}
export function canFullscreen() {
    return !!(
        window.document.documentElement.requestFullscreen ||
        window.document.documentElement.mozRequestFullScreen ||
        window.document.documentElement.webkitRequestFullScreen
    )
}
export function canScreenLock() {
    return !!( window.screen.orientation && window.screen.orientation.lock )
}
export function go_fullscreen() {
    console.log( 'enter fullscreen' )
    const docEl = document.documentElement
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen
    if ( requestFullScreen ) {
        try {
            const promise = requestFullScreen.call( docEl )
            promise.then(
                success => space.s( 'supposed_fullscreen' ).next( true ),
                failure => console.error( 'Failed to enter fullscreen:', failure ),
            )
        }
        catch(e) {
            console.error( 'Failed calling requestFullScreen function:', e )
        }
    }
    else {
        console.error( 'No fullscreen entering function available.' )
    }
}

