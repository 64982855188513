


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

export enum EDialogEvents {
    SHOW_TOURNAMENT_INFO = 'show_tournament_info',
    SHOW_PLAYER_INFO = 'show_player_info',
    SHOW_GAME_INFO = 'show_game_info',
    HIDE_ALL_DIALOGS = 'hide_all_dialogs'
}

@Component({
    computed: {},
})
export default class Dialogs extends Vue {
    playerUuid: string | null = null
    gameUuid: string | null = null
    tournamentUuid: string | null = null

    get active() {
        return this.playerUuid || this.gameUuid || this.tournamentUuid
    }

    created() {
        this.$root.$on(EDialogEvents.SHOW_GAME_INFO, (uuid: string) => {
            this.showGameInfo(uuid)
        })
        this.$root.$on(EDialogEvents.SHOW_PLAYER_INFO, (uuid: string) => {
            this.showPlayerInfo(uuid)
        })
        this.$root.$on(EDialogEvents.SHOW_TOURNAMENT_INFO, (uuid: string) => {
            console.log('1234', uuid)
            this.showTournamentInfo(uuid)
        })
        this.$root.$on(EDialogEvents.HIDE_ALL_DIALOGS, () => {
            this.hideTournamentInfo();
            this.hideGameInfo();
            this.hidePlayerInfo();
        })
    }

    beforeDestroy() {
        this.$root.$off(EDialogEvents.SHOW_GAME_INFO)
        this.$root.$off(EDialogEvents.SHOW_PLAYER_INFO)
        this.$root.$off(EDialogEvents.SHOW_TOURNAMENT_INFO)
        this.$root.$off(EDialogEvents.HIDE_ALL_DIALOGS)
    }

    showGameInfo(uuid: string) {
        this.gameUuid = uuid
    }
    hideGameInfo() {
        this.gameUuid = null
    }

    showPlayerInfo(uuid: string) {
        this.playerUuid = uuid
    }
    hidePlayerInfo() {
        this.playerUuid = null
    }

    showTournamentInfo(uuid: string) {
        this.tournamentUuid = uuid
    }
    hideTournamentInfo() {
        this.tournamentUuid = null
    }
}
