









import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isMobile } from 'mobile-device-detect';
import { EDialogEvents } from './Dialogs.vue';
@Component({        
})
export default class Blackout extends Vue {
    @Prop({ type:Boolean, default: true }) readonly isActive!: boolean
    onClick() {
        if (!isMobile && this.isActive) {
            this.$emit("close");
            this.$root.$emit(EDialogEvents.HIDE_ALL_DIALOGS)
            this.$router.push( this.localePath('/'));
        }
    }
}
