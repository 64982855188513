//
//
//
//
//
//
//
//

import Vue from 'vue'

//import VModal from 'vue-js-modal'
//Vue.use(VModal, { dialog: true })

export default {
    methods: {
        doActivate() {
            this.$emit('activate')
        },
    },
}
